<template>
  <div v-if="vehicle" id="service-system">
    <div class="service-tabs">
      <button
        class="flex-grow truncate reyesol-services-tab"
        :class="{
          selected: activeTab === 'system',
        }"
        @click="activeTab = 'system'"
      >
        {{ i18n.t("services.system.tabs.general") }}
      </button>
      <button
        class="flex-grow truncate flex flex-row justify-center gap-1 reyesol-services-tab"
        :class="{
          selected: activeTab === 'events',
        }"
        @click="activeTab = 'events'"
      >
        <span class="truncate">{{
          i18n.t("services.system.tabs.events")
        }}</span>
        <span
          v-if="active_alerts && active_alerts.length > 0"
          class="rounded-full bg-reyesol-gray px-2"
          >{{ active_alerts.length }}</span
        >
      </button>
      <button
        class="flex-grow truncate reyesol-services-tab"
        :class="{
          selected: activeTab === 'contracts',
        }"
        @click="activeTab = 'contracts'"
      >
        {{ i18n.t("services.system.tabs.contracts") }}
      </button>
      <button
        class="flex-grow truncate reyesol-services-tab"
        :class="{
          selected: activeTab === 'config',
        }"
        @click="activeTab = 'config'"
      >
        {{ i18n.t("services.system.tabs.config") }}
      </button>
    </div>

    <!-- system -->
    <div v-show="activeTab === 'system'" class="report-box">
      <div class="box p-3 text-center">
        <slot></slot>
        <div class="grid grid-cols-2 gap-4">
          <VehicleParameter
            :name="i18n.t('vehicles.plate')"
            :value="vehicle.plate"
          />
          <VehicleParameter
            :name="i18n.t('vehicles.model')"
            :value="vehicle.model"
          />
          <VehicleParameter
            :name="
              vehicle.vehicle_type == 'U'
                ? i18n.t('vehicles.unit_code')
                : i18n.t('vehicles.serial')
            "
            :value="
              vehicle.vehicle_type == 'U' ? vehicle.unit_code : vehicle.serial
            "
          />
          <VehicleParameter
            :name="i18n.t('vehicles.type')"
            :value="i18n.t(`vehicles.types.${vehicle.vehicle_type}`)"
          />
          <VehicleParameter
            v-if="vehicle.vehicle_subtype"
            :name="i18n.t('vehicles.subtype')"
            :value="vehicle.vehicle_subtype"
          />
        </div>
      </div>
      <div
        v-if="data && data.power"
        class="box p-5 mt-5 flex items-center justify-center"
      >
        <div
          v-if="data.power.external_voltage !== null"
          class="flex flex-wrap items-center justify-center"
        >
          <!--Power Level-->
          <VehicleParameter
            :name="
              i18n.t(
                `services.eyepower.external_voltage.${vehicle.vehicle_type}.name`,
              )
            "
            icon="BatteryChargingIcon"
            :setclass="
              () =>
                voltage_alert
                  ? 'bg-reyesol-alert-alarm'
                  : voltage_warning
                  ? 'bg-reyesol-alert-warning'
                  : 'bg-reyesol-gray'
            "
            :value="data.power.external_voltage + ' V'"
          />
        </div>
        <div
          v-if="data.power.general_status !== null"
          class="flex flex-wrap justify-around"
        >
          <VehicleParameter
            :active="data.power.general_status !== null"
            :name="
              i18n.t(
                `services.eyepower.general_status.${vehicle.vehicle_type}.name`,
              )
            "
            icon="ZapIcon"
            :value="
              data.power.general_status
                ? i18n.t(
                    `services.eyepower.general_status.${vehicle.vehicle_type}.on`,
                  )
                : i18n.t(
                    `services.eyepower.general_status.${vehicle.vehicle_type}.off`,
                  )
            "
          />
        </div>
        <div
          v-if="data.power.power_mode !== null"
          class="flex flex-wrap justify-around"
        >
          <VehicleParameter
            :active="data.power.power_mode !== null"
            :name="i18n.t('services.eyepower.power_mode.name')"
            icon="ArchiveIcon"
            :value="
              data.power.general_status
                ? i18n.t('services.eyepower.power_mode.opened')
                : i18n.t('services.eyepower.power_mode.closed')
            "
          />
        </div>
      </div>
    </div>

    <!-- events -->
    <div v-show="activeTab === 'events'" class="report-box">
      <div v-if="active_alerts.length == 0" class="box p-5">
        <h2>{{ i18n.t("services.system.events.no-events") }}</h2>
      </div>
      <div v-else>
        <div class="flex flex-col gap-3">
          <div
            v-for="alert in active_alerts"
            :key="alert.id"
            class="report-box-2"
          >
            <div class="box p-6 space-y-2">
              <div class="text-lg">
                {{ alert.description }}
              </div>
              <div
                class="text-sm"
                :class="{
                  'text-gray-700': !alert.severity || alert.severity == 0,
                  'text-reyesol-red': alert.severity == 2,
                  'text-reyesol-yellow': alert.severity == 1,
                }"
              >
                {{ i18n.t(`alerts.severity.${alert.severity}`) }}
              </div>
              <div class="text-base font-light">
                {{ dayjs(alert.created_at).format("YYYY-MM-DD HH:mm") }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- contracts -->
    <div v-show="activeTab === 'contracts'" class="report-box">
      <div class="flex-cols">
        <div
          v-for="av in active_agreements"
          :key="av.id"
          class="report-box-2 my-6"
        >
          <div class="box p-6 space-y-2">
            <div class="text-lg">
              {{ i18n.t(`services.${av.code}.name`) }}
            </div>
            <div
              class="text-sm"
              :class="{
                'text-gray-700': !av.color || av.color == 'gray',
                'text-reyesol-green': av.color == 'green',
                'text-reyesol-red': av.color == 'red',
                'text-reyesol-blue': av.color == 'blue',
                'text-reyesol-yellow': av.color == 'yellow',
              }"
            >
              {{ i18n.t(`agreements.state.${av.color}`) }}
            </div>
            <div class="text-base font-light">
              {{ dayjs(av.active_from).format("YYYY-MM-DD") }} -
              {{ dayjs(av.active_to).format("YYYY-MM-DD") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- config -->
    <div v-show="activeTab === 'config'" class="report-box">
      <div class="box p-5 flex flex-col overflow-hidden">
        <div class="dropdown p-2">
          <button
            class="dropdown-toggle w-full btn btn-sm btn-warning px-4 mt-2"
            aria-expanded="false"
          >
            {{ i18n.t("services.system.config.logging_interval_button") }}
          </button>
          <div class="dropdown-menu w-80">
            <div class="dropdown-menu__content box p-4">
              <CmdSelect
                :options="intervals_list"
                :initial-value="actual_interval"
                :name="i18n.t('services.system.config.logging_interval')"
                :description="
                  i18n.t('services.system.config.logging_interval_description')
                "
                @send="(v) => sendInterval(v)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-reyesol-report" @click="requestReport">
      <FileTextIcon />
      {{ i18n.t("general.request-generic-report") }}
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted, onUnmounted, reactive } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import CmdSelect from "@/components/commands/CmdSelect.vue";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";

export default {
  components: {
    CmdSelect,
    VehicleParameter,
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();
    const data = reactive(ref({ power: null }));
    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const active_alerts = ref([]);
    const active_agreements = ref([]);
    const voltage_warning = ref(false);
    const voltage_alert = ref(false);

    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) {
          initService();
        } else {
          destroyService();
        }
      },
      { immediate: true },
    );

    function updatePower(power) {
      if (data.value == null) return;
      data.value.power = power;
    }

    onMounted(() => {
      EventBus.on("vehicle:updates:power", updatePower);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:power");
    });

    async function initService() {
      voltage_alert.value = vehicle.value.alerts.find((a) => a.alert_id == 36);
      voltage_warning.value = vehicle.value.alerts.find((a) => a.alert_id == 6);
      active_alerts.value = vehicle.value.alerts
        .map((active_alert) => {
          const alerts = store.getters["alerts/collection"];
          const alert = alerts.find((a) => a.id == active_alert.alert_id);
          active_alert.severity = alert.severity;
          active_alert.description = alert.description;
          active_alert.time = dayjs(active_alert.created_at);

          return active_alert;
        })
        .sort((a, b) => {
          if (a.time.isSame(b, "minute")) return 0;
          if (a.time.isBefore(b, "minute")) return -1;
          return 0;
        });

      active_agreements.value = vehicle.value.active_agreements.map(
        (agreement) => {
          const agreements = store.getters["agreements/collection"];
          const from = dayjs(agreement.active_from);
          const to = dayjs(agreement.active_to);

          let color = "gray";
          if (from.diff(dayjs(), "day") <= 0) {
            color = "green";
            const toDiff = to.diff(dayjs(), "day");
            if (toDiff <= 0) color = "red";
            if (toDiff > 0 && toDiff <= 30) color = "yellow";
          }
          agreement.color = color;
          agreement.code = agreements.find(
            (a) => a.id == agreement.agreement_id,
          ).code;
          return agreement;
        },
      );

      const has_eyepower = vehicle.value.active_agreements.find(
        (o) => o.agreement_id == 27,
      );
      if (has_eyepower) {
        const dataResult = await store.dispatch(
          "eyepower/getVehicleData",
          vehicle.value.id,
        );
        data.value = dataResult.validated;
      }
    }

    function destroyService() {
      // destroy service
    }

    // Tabs
    const activeTab = ref("system");

    // requestReport service
    async function requestReport() {
      console.log("requestReport");

      const customer = ref(store.getters["auth/customer"]);

      const active_sensors = store.getters["globalMap/selectedVehicleSensors"];

      console.log("has_eyegeo: ", active_sensors.eyegeo);
      if (customer.value.configurations.geocoding_general_report_active)
        active_sensors.eyegeo.push("eyegeo.address");

      emit(
        "report-request",
        [],
        "custom",
        Object.values(active_sensors).flat(1),
      );
    }

    async function sendInterval(interval) {
      actual_interval.value = null;
      const result = await store.dispatch("vehicles/setLogInterval", {
        vehicleId: vehicle.value.id,
        value: interval,
      });
      if (result.success) {
        actual_interval.value = interval;
        EventBus.emit("notification:show", {
          type: "info",
          title: i18n.t("services.eyecontrol.command_submitted"),
        });
      }
    }
    const actual_interval = ref(null);

    const intervals_list = [
      { key: "900", label: "15 minuti" },
      { key: "1800", label: "30 minuti" },
      { key: "2700", label: "45 minuti" },
      { key: "3600", label: "60 minuti" },
    ];

    return {
      i18n,
      data,
      vehicle,
      active_alerts,
      active_agreements,
      dayjs,
      actual_interval,
      intervals_list,
      sendInterval,
      requestReport,
      activeTab,
      voltage_alert,
      voltage_warning,
    };
  },
};
</script>
