import { createRouter, createWebHistory } from "vue-router";
import store from "../store";

// base pages
import TopMenu from "../layouts/top-menu/Main.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import Login from "../views/login/Login.vue";
import Reset from "../views/login/Reset.vue";
import ErrorPage from "../views/error-page/ErrorPage.vue";

// customers
import CustomersIndex from "../views/customers/CustomersIndex.vue";

// agreements
import AgreementsIndex from "../views/agreements/AgreementsIndex.vue";

// notifications
import NotificationsIndex from "../views/notifications/NotificationsIndex.vue";

// active alerts
import ActiveAlertsIndex from "../views/active_alerts/ActiveAlertsIndex.vue";

// alerts
import AlertsIndex from "../views/alerts/AlertsIndex.vue";

// users
import UsersIndex from "../views/users/UsersIndex.vue";

// reports
import ReportsIndex from "../views/reports/ReportsIndex.vue";

// devices
import DevicesIndex from "../views/devices/DevicesIndex.vue";

// drivers
import DriversIndex from "../views/drivers/DriversIndex.vue";

// drivers
import DriversActivityIndex from "../views/drivers/DriversActivityIndex.vue";

// tocs
import TotalCostOwnershipIndex from "../views/tcos/TotalCostOwnershipIndex.vue";

// view report table
import Report from "../views/reports/Report.vue";

// global map
import GlobalMap from "../views/global-map/GlobalMap.vue";

// hubs
import HubsIndex from "../views/hubs/HubsIndex.vue";

// pois
import PoisIndex from "../views/pois/PoisIndex.vue";

// fleets
import FleetsIndex from "../views/fleets/FleetsIndex.vue";

// vehicles
import VehiclesIndex from "../views/vehicles/VehiclesIndex.vue";

// services
import ServicesDashboard from "../views/services/Dashboard.vue";
import EyecoldDashboard from "../views/services/eyecold/Dashboard.vue";
import EyecoldShow from "../views/services/eyecold/Show.vue";
import EyegeoDashboard from "../views/services/eyegeo/Dashboard.vue";
import EyegeoShow from "../views/services/eyegeo/Show.vue";
import EyewayDashboard from "../views/services/eyeway/Dashboard.vue";
import EyewayShow from "../views/services/eyeway/Show.vue";
import CarbontaxDashboard from "../views/services/carbontax/Dashboard.vue";
import CarbontaxShow from "../views/services/carbontax/Show.vue";
import EyefuelDashboard from "../views/services/eyefuel/Dashboard.vue";
import EyefuelShow from "../views/services/eyefuel/Show.vue";
import EyelinkDashboard from "../views/services/eyelink/Dashboard.vue";
import EyelinkShow from "../views/services/eyelink/Show.vue";
import EyepharmaDashboard from "../views/services/eyepharma/Dashboard.vue";
import EyepharmaShow from "../views/services/eyepharma/Show.vue";
import EyeserviceDashboard from "../views/services/eyeservice/Dashboard.vue";
import EyeserviceShow from "../views/services/eyeservice/Show.vue";
import EyeshareDashboard from "../views/services/eyeshare/Dashboard.vue";
import EyeshareShow from "../views/services/eyeshare/Show.vue";
import FGasDashboard from "../views/services/f-gas/Dashboard.vue";
import FGasShow from "../views/services/f-gas/Show.vue";
import GeofencingDashboard from "../views/services/geofencing/Dashboard.vue";
import GeofencingShow from "../views/services/geofencing/Show.vue";

const routes = [
  {
    path: "/",
    component: TopMenu,
    redirect: "/dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
      },

      // users
      {
        path: "/users",
        name: "users",
        component: UsersIndex,
      },

      // customers
      {
        path: "/customers",
        name: "customers",
        component: CustomersIndex,
      },

      // agreements
      {
        path: "/agreements",
        name: "agreements",
        component: AgreementsIndex,
      },

      // reports
      {
        path: "/reports",
        name: "reports",
        component: ReportsIndex,
      },

      // devices
      {
        path: "/devices",
        name: "devices",
        component: DevicesIndex,
      },

      // drivers
      {
        path: "/drivers",
        name: "drivers",
        component: DriversIndex,
      },

      // tcos
      {
        path: "/tcos",
        name: "tcos-index",
        component: TotalCostOwnershipIndex,
      },

      // driversactivity
      {
        path: "/drivers/activity",
        name: "drivers-activity-index",
        component: DriversActivityIndex,
      },

      // alerts
      {
        path: "/alerts",
        name: "alerts",
        component: AlertsIndex,
      },

      // active alerts
      {
        path: "/active-alerts",
        name: "active-alerts",
        component: ActiveAlertsIndex,
      },

      // report table
      {
        path: "/report",
        name: "single_report",
        props: (route) => ({ report_id: route.query.r }),
        component: Report,
      },

      // notifications
      {
        path: "/notifications",
        name: "notifications",
        component: NotificationsIndex,
      },

      // global map
      {
        path: "/global-map",
        name: "global-map",
        props: (route) => ({ vehicle_id: route.query.v }),
        component: GlobalMap,
      },
      // vehicle
      {
        path: "/details/:vehicle_id",
        name: "details",
        component: GlobalMap,
        props: true,
      },
      // vehicles
      {
        path: "/vehicles",
        name: "vehicles-index",
        component: VehiclesIndex,
      },
      // fleets
      {
        path: "/fleets",
        name: "fleets-index",
        component: FleetsIndex,
      },

      // hubs
      {
        path: "/hubs",
        name: "hubs-index",
        component: HubsIndex,
      },

      // pois
      {
        path: "/pois",
        name: "pois-index",
        component: PoisIndex,
      },

      // services
      {
        path: "/services",
        name: "services-dashboard",
        component: ServicesDashboard,
      },

      // eyecold
      {
        path: "/services/eyecold",
        name: "eyecold-dashboard",
        component: EyecoldDashboard,
      },
      {
        path: "/services/eyecold/show",
        name: "eyecold-show",
        component: EyecoldShow,
      },

      // eyegeo
      {
        path: "/services/eyegeo",
        name: "eyegeo-dashboard",
        component: EyegeoDashboard,
      },
      {
        path: "/services/eyegeo/show",
        name: "eyegeo-show",
        component: EyegeoShow,
      },

      // eyeway
      {
        path: "/services/eyeway",
        name: "eyeway-dashboard",
        component: EyewayDashboard,
      },
      {
        path: "/services/eyeway/show",
        name: "eyeway-show",
        component: EyewayShow,
      },

      // carbontax
      {
        path: "/services/carbontax",
        name: "carbontax-dashboard",
        component: CarbontaxDashboard,
      },
      {
        path: "/services/carbontax/show",
        name: "carbontax-show",
        component: CarbontaxShow,
      },

      // eyefuel
      {
        path: "/services/eyefuel",
        name: "eyefuel-dashboard",
        component: EyefuelDashboard,
      },
      {
        path: "/services/eyefuel/show",
        name: "eyefuel-show",
        component: EyefuelShow,
      },

      // eyelink
      {
        path: "/services/eyelink",
        name: "eyelink-dashboard",
        component: EyelinkDashboard,
      },
      {
        path: "/services/eyelink/show",
        name: "eyelink-show",
        component: EyelinkShow,
      },

      // eyepharma
      {
        path: "/services/eyepharma",
        name: "eyepharma-dashboard",
        component: EyepharmaDashboard,
      },
      {
        path: "/services/eyepharma/show",
        name: "eyepharma-show",
        component: EyepharmaShow,
      },

      // eyeservice
      {
        path: "/services/eyeservice",
        name: "eyeservice-dashboard",
        component: EyeserviceDashboard,
      },
      {
        path: "/services/eyeservice/show",
        name: "eyeservice-show",
        component: EyeserviceShow,
      },

      // eyeshare
      {
        path: "/services/eyeshare",
        name: "eyeshare-dashboard",
        component: EyeshareDashboard,
      },
      {
        path: "/services/eyeshare/show",
        name: "eyeshare-show",
        component: EyeshareShow,
      },

      // f-gas
      {
        path: "/services/f-gas",
        name: "f-gas-dashboard",
        component: FGasDashboard,
      },
      {
        path: "/services/f-gas/show",
        name: "f-gas-show",
        component: FGasShow,
      },

      // geofencing
      {
        path: "/services/geofencing",
        name: "geofencing-dashboard",
        component: GeofencingDashboard,
      },
      {
        path: "/services/geofencing/show",
        name: "geofencing-show",
        component: GeofencingShow,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/reset/:token/:email",
    name: "reset",
    component: Reset,
  },
  {
    path: "/error-page",
    name: "error-page",
    component: ErrorPage,
  },
  {
    path: "/:pathMatch(.*)*",
    component: ErrorPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const token = store.getters["auth/token"];
  if (!token && to.path != "/login" && to.path.indexOf("/reset/") !== 0) {
    next("/login");
    return;
  }

  if (token && (to.path == "/login" || to.path.indexOf("/reset/") === 0)) {
    next("/");
    return;
  }

  next();
});

export default router;
