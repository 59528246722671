import { pins as pinsApi } from "@/api";
import { vehicles as vehiclesApi } from "@/api";
import { reports } from "@/api";
import { hubs as hubsApi } from "@/api";
import { pois as poisApi } from "@/api";

const DEFAULT_SERVICE = "system";
const DEFAULT_FEATURE = "all";
const DEFAULT_SUBTYPE = null;

const state = () => {
  return {
    controller: null,
    pins: [],
    requestedService: DEFAULT_SERVICE,
    requestedFeature: DEFAULT_FEATURE,
    requestedSubtype: DEFAULT_SUBTYPE,
    selectedVehicle: null,
    externalSelectedVehicle: null,
    selectedHub: null,
    externalSelectedHub: null,
    selectedPoi: null,
    externalSelectedPoi: null,
    selectedVehicleSensors: null,
  };
};

// getters
const getters = {
  controller: (state) => state.controller,
  pins: (state) => state.pins,
  requestedService: (state) => state.requestedService,
  requestedFeature: (state) => state.requestedFeature,
  requestedSubtype: (state) => state.requestedSubtype,
  selectedVehicle: (state) => state.selectedVehicle,
  selectedVehicleSensors: (state) => state.selectedVehicleSensors,
  externalSelectedVehicle: (state) => state.externalSelectedVehicle,
  selectedHub: (state) => state.selectedHub,
  externalSelectedHub: (state) => state.externalSelectedHub,
  selectedPoi: (state) => state.selectedPoi,
  externalSelectedPoi: (state) => state.externalSelectedPoi,
};

// actions
const actions = {
  setController: ({ commit }, controller) =>
    commit("SET_CONTROLLER", controller),

  setRequestedService: ({ commit }, serviceKey) =>
    commit("SET_RQ_SERVICE", serviceKey),
  resetRequestedService: ({ commit }) => commit("RESET_RQ_SERVICE"),

  setRequestedFeature: ({ commit }, featureKey) =>
    commit("SET_RQ_FEATURE", featureKey),
  resetRequestedFeature: ({ commit }) => commit("RESET_RQ_FEATURE"),

  setRequestedSubtype: ({ commit }, subtypeKey) =>
    commit("SET_RQ_SUBTYPE", subtypeKey),
  resetRequestedSubtype: ({ commit }) => commit("RESET_RQ_SUBTYPE"),

  setExternalVehicle: ({ commit }, vehicle) =>
    commit("SET_EXT_VEHICLE", vehicle),
  resetExternalVehicle: ({ commit }) => commit("RESET_EXT_VEHICLE"),

  resetSelectedVehicle: ({ commit }) => commit("RESET_VEHICLE"),

  setExternalHub: ({ commit }, hub) => commit("SET_EXT_HUB", hub),
  resetExternalHub: ({ commit }) => commit("RESET_EXT_HUB"),

  setExternalPoi: ({ commit }, poi) => commit("SET_EXT_POI", poi),
  resetExternalPoi: ({ commit }) => commit("RESET_EXT_POI"),

  clearRequested({ dispatch }) {
    dispatch("resetRequestedService");
    dispatch("resetRequestedFeature");
    dispatch("resetRequestedSubtype");
  },

  clear({ dispatch }) {
    //dispatch("resetRequestedService");
    //dispatch("resetRequestedFeature");
    dispatch("resetSelectedVehicle");
    dispatch("resetExternalVehicle");
    dispatch("resetExternalHub");
    dispatch("resetExternalPoi");
  },

  async getPins({ commit, getters }) {
    const result = await pinsApi.getCollection();
    result.validated = getters["pins"];
    if (result.success) {
      commit("SET_PINS", result.data);
      result.validated = getters["pins"];
    }
    return result;
  },

  async getVehicle({ commit, getters }, vehicleId) {
    const result = await vehiclesApi.getEntity(vehicleId);
    result.validated = null;
    if (result.success) {
      commit("SET_VEHICLE", result.data);
      result.validated = getters["selectedVehicle"];
    }
    return result;
  },

  async getHub({ commit, getters }, hubId) {
    const result = await hubsApi.getEntity(hubId);
    result.validated = null;
    if (result.success) {
      commit("SET_HUB", result.data);
      result.validated = getters["selectedHub"];
    }
    return result;
  },

  async getPoi({ commit, getters }, poiId) {
    const result = await poisApi.getEntity(poiId);
    result.validated = null;
    if (result.success) {
      commit("SET_POI", result.data);
      result.validated = getters["selectedPoi"];
    }
    return result;
  },

  async getVehicleSensons(_, { vehicleId, services, from, to, type }) {
    return await vehiclesApi.requestReport(vehicleId, services, from, to, type);
  },

  async requestReport(_, { vehicleId, services, from, to, type }) {
    if (vehicleId)
      return await vehiclesApi.requestReport(
        vehicleId,
        services,
        from,
        to,
        type,
      );
    else
      return await reports.requestReport(vehicleId, services, from, to, type);
  },
};

// mutations
const mutations = {
  SET_CONTROLLER(state, controller) {
    state.controller = controller;
  },
  SET_RQ_SERVICE(state, serviceKey) {
    state.requestedService = serviceKey;
  },
  RESET_RQ_SERVICE(state) {
    state.requestedService = DEFAULT_SERVICE;
  },
  SET_RQ_FEATURE(state, featureKey) {
    state.requestedFeature = featureKey;
  },
  RESET_RQ_FEATURE(state) {
    state.requestedFeature = DEFAULT_FEATURE;
  },
  SET_RQ_SUBTYPE(state, subtypeKey) {
    state.requestedSubtype = subtypeKey;
  },
  RESET_RQ_SUBTYPE(state) {
    state.requestedSubtype = DEFAULT_SUBTYPE;
  },
  SET_VEHICLE(state, vehicle) {
    state.selectedVehicle = vehicle;
    if (vehicle.configurations.length > 0) {
      state.selectedVehicle.configurations = vehicle.configurations.reduce(
        function (map, obj) {
          map[obj.parameter] = obj.value;
          return map;
        },
        {},
      );
    }

    const vehicle_configuration = state.selectedVehicle.configurations;
    let eyecold = [];
    let eyecold_fridge = [];
    const eyedoor = [];
    const eyeway = [];
    const eyefuel = [];
    let eyegeo = [];
    let eyepower = [];
    let eyecontrol = [];
    let tandt = [];

    const has_eyegeo = state.selectedVehicle.active_agreements.find(
      (o) => o.agreement_id == 2,
    );
    if (has_eyegeo) {
      eyegeo = [
        "eyegeo.latitude",
        "eyegeo.longitude",
        "eyegeo.speed",
        "eyegeo.km",
      ];
    }

    const has_eyepower = state.selectedVehicle.active_agreements.find(
      (o) => o.agreement_id == 27,
    );
    if (has_eyepower) {
      eyepower = [
        "eyepower.external_voltage",
        "eyepower.power_mode",
        "eyepower.battery_voltage",
        "eyepower.battery_capacity",
      ];
    }
    const has_eyecontrol = state.selectedVehicle.active_agreements.find(
      (o) => o.agreement_id == 16,
    );
    if (has_eyecontrol) {
      eyecontrol = [
        "eyecontrol.user",
        "eyecontrol.command",
        "eyecontrol.command_data",
        "eyecontrol.status",
      ];
    }
    const has_tandt = state.selectedVehicle.active_agreements.find(
      (o) => o.agreement_id == 24,
    );
    if (has_tandt) {
      tandt = ["tandt.plate", "tandt.locked", "tandt.authorized"];
    }

    const has_eyecold = state.selectedVehicle.active_agreements.find(
      (o) => o.agreement_id == 3,
    );
    if (has_eyecold) {
      if (vehicle_configuration) {
        if (vehicle_configuration.fridge_temperature_sensors_number) {
          switch (vehicle_configuration.fridge_temperature_sensors_number) {
            case 4:
            case "4":
              eyecold.push("eyecold.T4");
            // eslint-disable-next-line no-fallthrough
            case 3:
            case "3":
              eyecold.push("eyecold.T3");
            // eslint-disable-next-line no-fallthrough
            case 2:
            case "2":
              eyecold.push("eyecold.T2");
            // eslint-disable-next-line no-fallthrough
            case 1:
            case "1":
              eyecold.push("eyecold.T1");
          }
        } else {
          eyecold = ["eyecold.T1", "eyecold.T2", "eyecold.T3", "eyecold.T4"];
        }
      }
    }
    const has_eyecold_fridge = state.selectedVehicle.active_agreements.find(
      (o) => o.agreement_id == 17,
    );
    if (has_eyecold_fridge) {
      eyecold_fridge = [
        "eyecold_fridge.overall_status", // MASTER
        "eyecold_fridge.engine_status",
        "eyecold_fridge.operating_mode",
        "eyecold_fridge.operating_mode_status",
        "eyecold_fridge.alarm_status", // MASTER
        "eyecold_fridge.hour_meter_electric",
        "eyecold_fridge.hour_meter_fuel",
        "eyecold_fridge.current_sensor",
        "eyecold_fridge.odl_num",
        "eyecold_fridge.odl_t1",
      ];
      if (vehicle_configuration) {
        if (vehicle_configuration.fridge_compartments_number) {
          switch (vehicle_configuration.fridge_compartments_number) {
            default:
            case 3:
            case "3":
              eyecold_fridge.push("eyecold_fridge.setpoint_t3");
              eyecold_fridge.push("eyecold_fridge.operation_status_c3");
              eyecold_fridge.push("eyecold_fridge.operation_mode_c3");
            // eslint-disable-next-line no-fallthrough
            case 2:
            case "2":
              eyecold_fridge.push("eyecold_fridge.setpoint_t2");
              eyecold_fridge.push("eyecold_fridge.operation_status_c2");
              eyecold_fridge.push("eyecold_fridge.operation_mode_c2");
            // eslint-disable-next-line no-fallthrough
            case 1:
            case "1":
              eyecold_fridge.push("eyecold_fridge.setpoint_t1");
              eyecold_fridge.push("eyecold_fridge.operation_status_c1");
              eyecold_fridge.push("eyecold_fridge.operation_mode_c1");
          }
        }
      }
    }

    const has_eyedoor = state.selectedVehicle.active_agreements.find(
      (o) => o.agreement_id == 4,
    );
    if (has_eyedoor) {
      if (vehicle_configuration) {
        if (vehicle_configuration.door1_active) {
          eyedoor.push("eyedoor.door_1");
        }
        if (vehicle_configuration.door2_active) {
          eyedoor.push("eyedoor.door_2");
        }
        if (vehicle_configuration.door3_active) {
          eyedoor.push("eyedoor.door_3");
        }
        if (vehicle_configuration.door4_active) {
          eyedoor.push("eyedoor.door_4");
        }
        if (vehicle_configuration.siren_active) {
          eyedoor.push("eyedoor.siren");
        }
      }
    }

    const has_eyefuel = state.selectedVehicle.active_agreements.find(
      (o) => o.agreement_id == 5,
    );
    if (has_eyefuel) {
      if (vehicle_configuration) {
        if (vehicle_configuration.tank_usage_active) {
          eyefuel.push("eyefuel.tank_usage");
        }
        if (vehicle_configuration.cap_status_active) {
          eyefuel.push("eyefuel.cap_status");
        }
        if (vehicle_configuration.tank_usage_active) {
          eyefuel.push("eyefuel.alarm_status");
        }
      }
    }

    const has_eyeway = state.selectedVehicle.active_agreements.find(
      (o) => o.agreement_id == 8,
    );
    if (has_eyeway) {
      if (vehicle_configuration) {
        if (vehicle_configuration.tyre_pressure_status_active) {
          eyeway.push("eyeway.tyres_pressure_status");
        }
        if (vehicle_configuration.breaks_status_active) {
          eyeway.push("eyeway.breaks_status");
        }
      }
      eyeway.push("eyeway.odometer");
      eyeway.push("eyeway.speed");
      eyeway.push("eyeway.load");
    }

    state.selectedVehicleSensors = {
      eyegeo: eyegeo,
      eyecold: eyecold,
      eyecold_fridge: eyecold_fridge,
      eyedoor: eyedoor,
      eyecontrol: eyecontrol,
      eyeway: eyeway,
      eyefuel: eyefuel,
      eyepower: eyepower,
      tandt: tandt,
    };
  },
  SET_HUB(state, hub) {
    state.selectedHub = hub;
  },
  SET_POI(state, poi) {
    state.selectedPoi = poi;
  },
  RESET_VEHICLE(state) {
    state.selectedVehicle = null;
  },
  SET_EXT_VEHICLE(state, vehicle) {
    state.externalSelectedHub = null;
    state.externalSelectedVehicle = vehicle;
  },
  RESET_EXT_VEHICLE(state) {
    state.externalSelectedVehicle = null;
  },
  SET_EXT_HUB(state, hub) {
    state.externalSelectedVehicle = null;
    state.externalSelectedHub = hub;
  },
  RESET_EXT_HUB(state) {
    state.externalSelectedHub = null;
  },
  SET_EXT_POI(state, poi) {
    state.externalSelectedVehicle = null;
    state.externalSelectedPoi = poi;
  },
  RESET_EXT_POI(state) {
    state.externalSelectedPoi = null;
  },
  SET_PINS(state, pins) {
    state.pins = pins;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
