import { informations as api } from "@/api";

const state = () => {
  return {};
};

// getters
const getters = {};

// actions
const actions = {
  async search(_, query) {
    const result = await api.search(query);
    result.validated = {
      hubs: [],
      fleets: [],
      vehicles: [],
      driver_vehicles: [],
    };
    if (result.success) {
      result.validated = {
        hubs: result.data.hubs.data,
        fleets: result.data.fleets.data,
        vehicles: result.data.vehicles.data,
        driver_vehicles: result.data.driver_vehicles,
      };
    }
    return result;
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
