<template>
  <div id="types">
    <div v-if="true" class="col-span-12 my-8">
      <div class="flex items-center h-10 intro-y justify-between">
        <h2 class="mr-5 text-lg font-medium truncate">
          TCO Analisi costi operativi
        </h2>
        <div class="flex">
          Ultimi:
          <div class="badge-reyesol-button-list">
            <button :class="{ active: period == 12 }" @click="setPeriod(12)">
              12 mesi
            </button>
            <button :class="{ active: period == 6 }" @click="setPeriod(6)">
              6 mesi
            </button>
            <button :class="{ active: period == 3 }" @click="setPeriod(3)">
              3 mesi
            </button>
            <button :class="{ active: period == 1 }" @click="setPeriod(1)">
              mese
            </button>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div
            class="relative zoom-in before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70"
          >
            <div class="p-5 box">
              <div class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="stroke-1.5 w-[28px] h-[28px] text-primary"
                >
                  <circle cx="8" cy="21" r="1"></circle>
                  <circle cx="19" cy="21" r="1"></circle>
                  <path
                    d="M2.05 2.05h2l2.66 12.42a2 2 0 0 0 2 1.58h9.78a2 2 0 0 0 1.95-1.57l1.65-7.43H5.12"
                  ></path>
                </svg>
              </div>
              <div class="mt-6 text-3xl font-medium leading-8">
                {{ helper.format_currency(stats.total_cost) }}
              </div>
              <div class="mt-1 text-base text-slate-500">Spese totali</div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div
            class="relative zoom-in before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70"
          >
            <div class="p-5 box">
              <div class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="stroke-1.5 w-[28px] h-[28px] text-pending"
                >
                  <rect x="2" y="5" width="20" height="14" rx="2"></rect>
                  <line x1="2" y1="10" x2="22" y2="10"></line>
                </svg>
              </div>
              <div class="mt-6 text-3xl font-medium leading-8">
                {{
                  helper.format_currency(stats.total_cost / stats.n_vehicles)
                }}
              </div>
              <div class="mt-1 text-base text-slate-500">
                Spesa media veicolo
              </div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div
            class="relative zoom-in before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70"
          >
            <div class="p-5 box">
              <div class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="stroke-1.5 w-[28px] h-[28px] text-warning"
                >
                  <rect x="2" y="3" width="20" height="14" rx="2" ry="2"></rect>
                  <line x1="8" y1="21" x2="16" y2="21"></line>
                  <line x1="12" y1="17" x2="12" y2="21"></line>
                </svg>
              </div>
              <div class="mt-6 text-3xl font-medium leading-8">
                {{ stats.total_activities }}
              </div>
              <div class="mt-1 text-base text-slate-500">Totale attività</div>
            </div>
          </div>
        </div>
        <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
          <div
            class="relative zoom-in before:content-[''] before:w-[90%] before:shadow-[0px_3px_20px_#0000000b] before:bg-slate-50 before:h-full before:mt-3 before:absolute before:rounded-md before:mx-auto before:inset-x-0 before:dark:bg-darkmode-400/70"
          >
            <div class="p-5 box">
              <div class="flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="stroke-1.5 w-[28px] h-[28px] text-success"
                >
                  <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div class="mt-6 text-3xl font-medium leading-8">
                {{ stats.n_vehicles }}
              </div>
              <div class="mt-1 text-base text-slate-500">
                Veicoli con attività
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-between">
      <search-title
        :placeholder="i18n.t('type_activities.search-by-name')"
        @search="updateActivitySearchQuery"
        >{{ i18n.t("navigation.type_activities") }}
        <div
          v-if="activities && activities.length > 0"
          class="text-xs inline md:block text-gray-600 w-auto"
        >
          <span class="hidden md:visible">Visualizzati </span>
          {{ activities.length }} costi operativi
        </div>
        <div v-else class="text-xs text-gray-600 w-auto">
          {{ i18n.t("type_activities.empty") }}
        </div>
      </search-title>

      <div class="flex justify-between py-2 md:items-center">
        <button class="btn btn-primary mr-1" @click="addNewActivity">
          <div class="w-10 h-10 rounded-md flex items-center justify-center">
            <PlusIcon />
          </div>
          <div class="ml-4 mr-auto">
            <div class="font-medium">Aggiungi costo operativo</div>
          </div>
        </button>
        <button
          class="btn btn-secondary"
          @click="onReportRequest(null, 'tcos', null, null)"
        >
          Report attività
        </button>
      </div>
    </div>
    <div class="flex flex-wrap mt-3">
      <div class="w-full lg:w-2/3">
        <TypeActivityCard
          v-for="a in activities"
          :key="a.id"
          :activity="a"
          :active="activeActivity && a.id == activeActivity.id"
          :vehicles="all_vehicles"
          :types="all_types"
          @updated="updateActivityCollection"
          @selected="handleActivitySelect"
          @canceled="handleActivityCancel"
        ></TypeActivityCard>
      </div>
      <div class="w-full pl-0 lg:pl-4 lg:w-1/3">
        <!-- new type -->
        <button class="btn btn-primary w-full mb-3" @click="addNewType">
          <div class="w-10 h-10 rounded-md flex items-center justify-center">
            <PlusIcon />
          </div>
          <div class="ml-4 mr-auto">
            <div class="font-medium">{{ i18n.t("types.add") }}</div>
          </div>
        </button>
        <div class="w-full">
          <!-- types -->
          <TypeCard
            v-for="d in types"
            :key="d.id"
            :type="d"
            :active="activeType && d.id == activeType.id"
            @updated="updateCollection"
            @selected="handleTypeSelect"
            @canceled="handleTypeCancel"
          ></TypeCard>
        </div>
      </div>
    </div>
    <!--Report slider-->
    <ReportSlider
      :active="reportSelectorOpened"
      :save="i18n.t('global-map.report.save')"
      :cancel="i18n.t('global-map.report.cancel')"
      :selected="report_selected"
      :enabled="report_enabled"
      :type="report_type"
      @save="onReportSave"
      @close="onReportClose"
    />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "@/store";
import dayjs from "dayjs";
import { useCollectionSearch } from "@/composables/collection-search";
import TypeCard from "./TypeCard.vue";
import TypeActivityCard from "./TypeActivityCard.vue";
import ReportSlider from "@/components/report-slide/ReportSlider.vue";
import EventBus from "@/libs/event-bus";
import { helper } from "@/utils/helper";

export default {
  components: { TypeCard, TypeActivityCard, ReportSlider },
  setup() {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const utc = require("dayjs/plugin/utc");
    dayjs.extend(utc);
    const i18n = useI18n();
    const store = useStore();

    const all_vehicles = ref([]);
    const all_types = ref([]);

    const stats = ref({
      n_vehicles: 0,
      total_cost: 0,
      total_activities: 0,
    });

    const period = ref(12);

    const page = ref(1);

    //reporting
    const report_selected = ref(["tco.report"]);
    const report_enabled = ref(["tco.report"]);
    const report_type = ref("tco");
    const reportSelectorOpened = ref(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onReportRequest(service, type, enabled, selected) {
      //console.log("onReportRequest", service, type, enabled, selected);
      report_selected.value.splice(0, report_selected.value.length);
      if (service) report_selected.value.push(...service);

      if (enabled) {
        report_enabled.value.splice(0, report_enabled.value.length);
        report_enabled.value.push(...enabled);
      }
      if (enabled) {
        report_enabled.value.splice(0, report_enabled.value.length);
        report_enabled.value.push(...enabled);
      }
      report_type.value = type;
      reportSelectorOpened.value = true;
    }

    function onReportClose() {
      reportSelectorOpened.value = false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    function onReportSave(services, from, to, type) {
      store.dispatch("globalMap/requestReport", {
        services: services.join(),
        from: dayjs(from).format(),
        to: dayjs(to).format(),
        type: "tco",
      });
      //console.log("onReportSave", services, from, to, type);

      EventBus.emit("notification:show", {
        type: "success",
        title: i18n.t("reports.notify.submitted"),
      });
    }

    const {
      updateSearchQuery,
      collectionToFilter,
      filteredCollection: types,
    } = useCollectionSearch(["name", "surname", "license", "note"]);

    const {
      updateSearchQuery: updateActivitySearchQuery,
      collectionToFilter: collectionActivityToFilter,
      filteredCollection: activities,
      externalFilter: typeFilter,
    } = useCollectionSearch(["vehicle.plate"]);

    typeFilter.value = function (collection) {
      if (activeType.value) {
        return collection.filter((a) => a.type.id == activeType.value.id);
      } else return collection;
    };

    collectionToFilter.value = [];

    async function fetchVehicles() {
      const response = await store.dispatch("vehicles/getCollection");
      //console.log("Response: ", response);

      all_vehicles.value = response.validated.filter((d) => d.active == 1);
    }

    async function fetchTypes() {
      const response = await store.dispatch("types/getCollection");
      //console.log("Response: ", response);
      collectionToFilter.value = response.validated.filter(
        (d) => d.active == 1,
      );
      all_types.value = response.validated.filter((d) => d.active == 1);
    }

    async function fetchStats() {
      const response = await store.dispatch("type_activities/getStats", {
        period: period.value,
      });
      //console.log("Response Stats: ", response);
      if (response.validated && response.validated.length == 1)
        stats.value = response.validated[0];
    }

    async function fetchTypeActivities() {
      const response = await store.dispatch("type_activities/getCollection", {
        page: page.value,
      });
      //console.log("Response activities_collection: ", response);
      collectionActivityToFilter.value = response.validated;
      if (activeType.value)
        collectionActivityToFilter.value =
          collectionActivityToFilter.value.filter(
            (a) => a.type.id == activeType.value.id,
          );
      collectionActivityToFilter.value =
        collectionActivityToFilter.value.filter((d) => d.id);
    }
    fetchStats();
    fetchTypes();
    fetchTypeActivities();

    // active type
    const activeType = ref(null);

    function handleTypeSelect(type) {
      selectType(type);
    }

    function handleTypeCancel() {
      activeType.value = null;
      collectionToFilter.value = collectionToFilter.value.filter((d) => d.id);
    }

    // active activity
    const activeActivity = ref(null);

    function handleActivitySelect(activity) {
      selectActivity(activity);
    }

    function handleActivityCancel() {
      activeActivity.value = null;
      collectionActivityToFilter.value =
        collectionActivityToFilter.value.filter((d) => d.id);
    }

    function selectType(type) {
      if (activeType.value != null && activeType.value.id == type.id) {
        activeType.value = null;
      } else {
        activeType.value = type;
      }
      fetchTypeActivities();
    }

    function selectActivity(activity) {
      activeActivity.value = activity;
    }

    // new type
    function addNewType() {
      if (activeType.value && !activeType.value.id) return;
      collectionToFilter.value = [
        {
          name: i18n.t("types.new"),
          repeat_after_km: null,
          repeat_after_hour: null,
          repeat_after_period: null,
          notice_after_km: null,
          notice_after_hour: null,
          notice_after_period: null,
          note: "",
        },
        ...collectionToFilter.value,
      ];
      activeType.value = collectionToFilter.value[0];
    }

    // new type
    function addNewActivity() {
      if (activeActivity.value && !activeActivity.value.id) return;
      collectionActivityToFilter.value = [
        {
          id: null,
          type_id: null,
          vehicle_id: null,
          pariod_from: null,
          pariod_to: null,
        },
        ...collectionActivityToFilter.value,
      ];
      activeActivity.value = collectionActivityToFilter.value[0];
    }

    function setPeriod(c) {
      period.value = c;
      fetchStats();
    }

    onMounted(async () => {
      await fetchTypes();
      await fetchVehicles();
      await fetchTypeActivities();
      await fetchStats();
    });

    function updateCollection(c) {
      collectionToFilter.value = c;
      fetchTypes();
    }

    function updateActivityCollection() {
      fetchTypeActivities();
    }

    return {
      i18n,
      dayjs,

      // fetch types
      all_types,
      all_vehicles,
      types,
      activities,

      period,
      setPeriod,
      helper,
      stats,

      updateCollection,
      updateActivityCollection,

      // active activity
      activeActivity,
      handleActivitySelect,
      handleActivityCancel,

      // active type
      activeType,
      handleTypeSelect,
      handleTypeCancel,

      // new type
      addNewType,
      addNewActivity,

      updateSearchQuery,
      updateActivitySearchQuery,

      reportSelectorOpened,
      onReportRequest,
      report_selected,
      report_enabled,
      report_type,
      onReportSave,
      onReportClose,
    };
  },
};
</script>
