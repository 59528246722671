<template>
  <div
    class="box p-4 mb-3 cursor-pointer"
    :class="{ 'ring-2 ring-reyesol-blue': active }"
    @click="select"
  >
    <!-- card -->
    <div v-if="!formActive" class="flex items-center justify-between">
      <div class="flex items-center">
        <div class="rounded-md">
          <UserCheckIcon v-if="active" class="w-10 h-10" />
        </div>
        <div class="ml-4 flex-1 mr-auto">
          <div class="font-medium">{{ getDriverName(driver) }}</div>
          <div class="text-gray-600 text-xs mt-0.5">
            {{ getDriverInformations(driver) }}
          </div>
        </div>
      </div>
      <div v-if="actions" class="flex">
        <button class="btn btn-secondary p-1 mr-1" @click.stop="showForm">
          <EditIcon class="w-5 h-5" />
        </button>
        <button class="btn btn-danger p-1" @click.stop="deleteEntity(driver)">
          <TrashIcon class="w-5 h-5" />
        </button>
      </div>
    </div>

    <!-- form -->
    <div v-else>
      <!-- name -->
      <div class="flex items-center mb-4">
        <label class="w-2/12 mr-4 capitalize">{{
          i18n.t("drivers.name")
        }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.name.$error"
          :errors="v$.name.$errors"
        >
          <input v-model="driver.name" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- surname -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{ i18n.t("drivers.surname") }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.surname.$error"
          :errors="v$.surname.$errors"
        >
          <input v-model="driver.surname" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- license -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{ i18n.t("drivers.license") }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.license.$error"
          :errors="v$.license.$errors"
        >
          <input v-model="driver.license" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- license_expiration_date -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{
          i18n.t("drivers.license_expiration_date")
        }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.license_expiration_date.$error"
          :errors="v$.license_expiration_date.$errors"
        >
          <input
            v-model="driver.license_expiration_date"
            type="text"
            class="form-control"
          />
        </validated-input>
      </div>

      <!-- ibutton_id -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{ i18n.t("drivers.ibutton_id") }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.ibutton_id.$error"
          :errors="v$.ibutton_id.$errors"
        >
          <input v-model="driver.ibutton_id" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- phone_number -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{ i18n.t("drivers.phone_number") }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.phone_number.$error"
          :errors="v$.phone_number.$errors"
        >
          <input
            v-model="driver.phone_number"
            type="text"
            class="form-control"
          />
        </validated-input>
      </div>

      <!-- email -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{ i18n.t("drivers.email") }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.email.$error"
          :errors="v$.email.$errors"
        >
          <input v-model="driver.email" type="email" class="form-control" />
        </validated-input>
      </div>

      <!-- note -->
      <div class="flex items-center mb-4 capitalize">
        <label class="w-2/12 mr-4">{{ i18n.t("drivers.note") }}</label>
        <validated-input
          class="w-10/12"
          :has-error="v$.note.$error"
          :errors="v$.note.$errors"
        >
          <input v-model="driver.note" type="text" class="form-control" />
        </validated-input>
      </div>

      <!-- save -->
      <div class="flex justify-end">
        <button class="btn btn-secondary mr-2" @click.stop="cancelForm()">
          {{ i18n.t("general.cancel") }}
        </button>

        <button class="btn btn-primary" @click.stop="save()">
          <loading-icon
            v-if="loading"
            icon="rings"
            color="white"
            class="mr-2"
          />
          {{ i18n.t("general.save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { helper } from "@/utils/helper";
import toast from "@/services/toast";
import EventBus from "@/libs/event-bus";

export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: true,
    },
    driver: {
      type: Object,
      required: true,
    },
  },

  emits: ["updated", "selected", "canceled"],

  setup(props, { emit }) {
    const i18n = useI18n();
    const store = useStore();

    // select
    function select() {
      if (!formActive.value) emit("selected", props.driver);
    }

    // form
    const name_error = ref(false);
    const name_error_array = ref([]);
    const formActive = ref(false);
    if (!props.driver.id) showForm();

    function showForm() {
      formActive.value = true;
    }

    function cancelForm() {
      formActive.value = false;
      emit("canceled", props.entity);
    }

    // validation
    const rules = {
      name: { required },
      surname: { required },
      license: {},
      license_expiration_date: {},
      ibutton_id: {},
      phone_number: {},
      note: {},
      email: {},
    };

    const v$ = useVuelidate(rules, props.driver);

    // save
    const loading = ref(false);
    async function save() {
      loading.value = true;
      v$.value.$touch();
      if (v$.value.$error) {
        loading.value = false;
        return;
      }

      let result = null;

      if ("id" in props.driver) {
        result = await store.dispatch("drivers/update", props.driver);
      } else {
        result = await store.dispatch("drivers/store", props.driver);
      }

      if (!result.success) {
        if (result.data["name"] != undefined) {
          name_error.value = true;
          name_error_array.value = result.data["name"];
        }

        for (const key in result.data) {
          toast.error(result.data[key]);
        }
        toast.error(result.errorMessage);
      }

      if (result.success) {
        formActive.value = false;
        emit("updated", result.validated);
      }

      loading.value = false;
    }

    // delete
    async function deleteEntity(driver) {
      EventBus.emit("confirm-dialog:show", {
        title: `${i18n.t("drivers.confirm-delete-title")} ${driver.code}?`,
        text: i18n.t("drivers.confirm-delete-text"),
        callback: async (confirm) => {
          if (confirm) {
            const result = await store.dispatch(
              "drivers/delete",
              props.driver.id,
            );
            if (result.success) emit("updated", result.validated);
          }
        },
      });
    }

    // utils
    const colors = store.getters["main/colors"];

    function getDriverName(driver) {
      if (driver.surname != null) return `${driver.name} (${driver.surname})`;
      return driver.name;
    }

    function getDriverInformations(driver) {
      const arr = [];
      if (driver.license != null) arr.push(driver.license);
      if (driver.license_expiration_date != null)
        arr.push("(" + driver.license_expiration_date + ")");
      if (driver.ibutton_id != null) arr.push("[" + driver.ibutton_id + "]");
      if (driver.phone_number != null) arr.push(driver.phone_number);
      return arr.join(", ");
    }

    return {
      i18n,
      name_error,
      name_error_array,
      // select
      select,

      // form
      v$,
      formActive,
      showForm,
      cancelForm,

      // save
      loading,
      save,

      // delete
      deleteEntity,

      // utils
      h: helper,
      colors,
      getDriverName,
      getDriverInformations,
    };
  },
};
</script>

<style></style>
