<template>
  <div id="vehicles-index">
    <DataTable
      ref="vehiclesTable"
      :columns="vehiclesDataTableColumns"
      :data="vehicles"
      :options="vehiclesDataTableOptions"
      :key="vehiclesDataTableColumns.length"
      class="display"
    />
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import DataTable from "datatables.net-vue3";
import DataTablesCore from "datatables.net-dt";
import dtItalianLanguage from "datatables.net-plugins/i18n/it-IT";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5";
import "datatables.net-buttons/js/buttons.print";
import jszip from "jszip";
import pdfmake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

DataTablesCore.defaults.language = dtItalianLanguage;

DataTable.use(DataTablesCore);
DataTablesCore.Buttons.jszip(jszip);
pdfMake.vfs = pdfFonts.pdfMake.vfs;
DataTablesCore.Buttons.pdfMake(pdfmake);

export default {
  components: { DataTable },
  setup() {
    const i18n = useI18n();
    const store = useStore();

    const vehicles = ref([]);
    const vehiclesDataTableColumns = ref([]);
    let agreementCounts;

    const vehiclesTable = ref();
    const vehiclesDataTableOptions = {
      layout: {
        topStart: {
          pageLength: {},
          buttons: ["spacer", "copy", "csv", "excel", "pdf", "print"],
        },
      },
    };

    onMounted(async () => {
      await fetchVehicles();
      await countActiveAgreements();

      vehiclesDataTableColumns.value = await calcVehiclesDataTableColumns();
    });

    function calcVehiclesDataTableColumns() {
      let columns = [
        { data: "plate", title: "Targa" },
        { data: "model", title: "Modello" },
        {
          data: "vehicle_type",
          title: "Tipologia",
          render: function (data) {
            let vehicleType;
            switch (data) {
              case "M":
                vehicleType = "Motrice";
                break;
              case "P":
                vehicleType = "Transpallet";
                break;
              case "R":
                vehicleType = "Rimorchio";
                break;
              case "S":
                vehicleType = "Semirimorchio";
                break;
              case "V":
                vehicleType = "Van";
                break;
              case "T":
                vehicleType = "Trattore/Motrice";
                break;
              case "C":
                vehicleType = "Centinato";
                break;
              case "U":
                vehicleType = "Autovettura";
                break;
              default:
                vehicleType = data;
                break;
            }
            return vehicleType;
          },
        },
        { data: "vehicle_subtype", title: "Sottotipologia" },
      ];

      if (checkIfServiceExists(30)) {
        columns = [
          ...columns,
          { data: "rent.renter_name", title: "Noleggiatore" },
          { data: "rent.rent_start", title: "Inizio noleggio" },
          { data: "rent.rent_end", title: "Fine noleggio" },
          { data: "rent.initial_km_contract", title: "Km iniziali" },
          { data: "rent.contracted_km", title: "Km contratto" },
        ];
      }

      return columns;
    }

    // fetch vehicles with rent data
    async function fetchVehicles() {
      const response = await store.dispatch("vehicles/getVehiclesWithRent");
      vehicles.value = response.validated;
    }

    async function countActiveAgreements() {
      agreementCounts = vehicles.value.reduce((counts, vehicle) => {
        vehicle.active_agreements.forEach((agreement) => {
          const { agreement_id } = agreement;
          counts[agreement_id] = (counts[agreement_id] || 0) + 1;
        });
        return counts;
      }, {});
    }

    function checkIfServiceExists(serviceId) {
      if (
        agreementCounts &&
        agreementCounts[serviceId] &&
        agreementCounts[serviceId] > 0
      )
        return true;

      return false;
    }

    return {
      i18n,

      vehicles,
      vehiclesTable,
      vehiclesDataTableColumns,
      vehiclesDataTableOptions,
      fetchVehicles,
    };
  },
};
</script>

<style>
@import "datatables.net-dt";
@import "datatables.net-buttons-dt";
</style>
