<template>
  <div class="h-full flex items-center justify-between flex-wrap">
    <div class="flex items-center">
      <template v-for="type in vehicleTypes" :key="type.id">
        <div v-if="getStatisticsOfType(type.code) > 0">
          <Tippy
            tag="a"
            href="javascript:;"
            :name="`stats-${type.code}-tooltip`"
            class="relative tooltip flex items-center h-6 md:h-8 sm:mr-8 last:mr-0"
            :content="i18n.t(`vehicles.types.${type.code}`)"
          >
            <img
              :src="require(`@/assets/images/vehicles/${type.code}-white.svg`)"
              class="h-1/2"
            />
            <div
              class="absolute -top-2 -right-6 text-xs rounded-full bg-white px-2 text-center font-bold text-black"
            >
              {{ getStatisticsOfType(type.code) }}
            </div>
          </Tippy>
          <div class="tooltip-content">
            <TippyContent
              :to="`stats-${type.code}-tooltip`"
              :options="{
                interactive: true,
              }"
            >
              <div
                v-if="type.code == 'U'"
                class="flex flex-row flex-wrap gap-2 justify-center"
              >
                <p class="w-full text-center mt-2">Sottotipologia:</p>
                <select class="form-select" v-model="vehicleSubtypeSelection">
                  <option value>Nessuno</option>
                  <option value="Pick-up">Pick-up</option>
                  <option value="City car">City car</option>
                  <option value="Berlina">Berlina</option>
                  <option value="SUV">SUV</option>
                  <option value="Auto elettrica">Auto elettrica</option>
                </select>
              </div>
              <div class="grid grid-cols-3 gap-4 py-4 px-2 justify-evenly">
                <div class="cursor-pointer" @click="filterType(type.code, 0)">
                  <ConnectionBadge type="standby" />
                  <div class="text-center mt-1 text-sm font-bold">
                    {{ i18n.t("features.standby.name") }}
                  </div>
                  <div class="text-center mt-1 text-lg font-bold">
                    {{ getStatisticsOfType(type.code, 0) }}
                  </div>
                </div>
                <div class="cursor-pointer" @click="filterType(type.code, 1)">
                  <ConnectionBadge type="connected" />
                  <div class="text-center mt-1 text-sm font-bold">
                    {{ i18n.t("features.connected.name") }}
                  </div>
                  <div class="text-center mt-1 text-lg font-bold">
                    {{ getStatisticsOfType(type.code, 1) }}
                  </div>
                </div>
                <div class="cursor-pointer" @click="filterType(type.code, 2)">
                  <ConnectionBadge type="disconnected" />
                  <div class="text-center mt-1 text-sm font-bold">
                    {{ i18n.t("features.disconnected.name") }}
                  </div>
                  <div class="text-center mt-1 text-lg font-bold">
                    {{ getStatisticsOfType(type.code, 2) }}
                  </div>
                </div>
              </div>
            </TippyContent>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, onUnmounted, ref } from "vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useI18n } from "vue3-i18n";
import ConnectionBadge from "./ConnectionBadge.vue";

export default defineComponent({
  props: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const i18n = useI18n();
    let stats_interval = null;
    // statistics
    const vehicleTypes = ref([]);
    const typesStatistics = ref([]);
    const vehicleSubtypeSelection = ref(null);
    onMounted(() => {
      fetchStats();
      stats_interval = setInterval(fetchStats, 60000);
    });
    onUnmounted(() => {
      if (stats_interval) clearInterval(stats_interval);
      stats_interval = false;
    });
    async function fetchStats() {
      vehicleTypes.value = (
        await store.dispatch("vehicles/getTypes")
      ).validated;
      typesStatistics.value = (
        await store.dispatch("vehicles/getTypesStatistics")
      ).validated;
    }
    function getStatisticsOfType(typeCode, statStatus = null) {
      const ts = typesStatistics.value.map((t) => {
        if (t.vehicle_type == typeCode) return t;
      });
      if (ts && ts.length > 0) {
        if (statStatus == null) {
          return ts.reduce(
            (o, n) => (n == null ? o : parseFloat(n.total) + o),
            0,
          );
        }
        return ts.reduce(
          (o, n) =>
            n == null || n.status != statStatus ? o : parseFloat(n.total) + o,
          0,
        );
      }
      return 0;
    }
    function filterType(type, status) {
      const service = "type_" + type;

      let feature;
      switch (status) {
        case 0:
          feature = "standby";
          break;
        case 1:
          feature = "connected";
          break;
        case 2:
          feature = "disconnected";
          break;
        default:
          feature = "standby";
          break;
      }

      store.dispatch("globalMap/setRequestedService", service);
      store.dispatch("globalMap/setRequestedFeature", feature);

      if (service == "type_U") {
        const vehicleSubtype = vehicleSubtypeSelection.value;
        console.log("sottotipologia in VehicleStatMenu: " + vehicleSubtype);
        store.dispatch("globalMap/setRequestedSubtype", vehicleSubtype);
        router.push({
          path: "/global-map",
          query: { f: feature, s: service, st: vehicleSubtype },
        });
      } else {
        router.push({
          path: "/global-map",
          query: { f: feature, s: service },
        });
      }
    }
    return {
      i18n,
      vehicleTypes,
      getStatisticsOfType,
      filterType,
      vehicleSubtypeSelection,
    };
  },
  components: { ConnectionBadge },
});
</script>
