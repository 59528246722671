<template>
  <div v-if="vehicle" id="service-eyecar">
    <!-- Total Vehicle Km -->
    <div
      v-if="data && data.position && data.position.km !== null"
      class="box flex flex-row justify-center items-center gap-4 p-3"
    >
      <label class="">Proprietà veicolo</label>
      <select
        v-model="valueOwnershipType"
        @change="updateOwnershipType()"
        class="form-select"
      >
        <option value="0">Di proprietà</option>
        <option value="1">Noleggio</option>
        <option value="2">Leasing</option>
      </select>
    </div>

    <!-- Vehicle Rent -->
    <div
      v-if="
        valueOwnershipType != 0 &&
        data &&
        data.position &&
        data.position.km !== null
      "
    >
      <!-- Vehicle Rent -->
      <span class="text-lg">Dati Noleggio/Leasing</span>
      <!-- Add Rent data Button -->
      <div v-if="data && !data.rent" class="w-full mr-2">
        <button
          class="btn btn-sm btn-primary mb-3 w-full"
          @click="addVehicleRent"
        >
          <div class="w-8 h-8 rounded-md flex items-center justify-center">
            <PlusIcon />
          </div>
          <div class="ml-4 mr-auto">
            <div class="font-medium">Aggiungi dati Noleggio/Leasing</div>
          </div>
        </button>
      </div>

      <!-- Add Vehicle Rent Form -->
      <div
        v-if="addingVehicleRent"
        class="flex-cols items-center justify-between p-5 border-2 border-color-gray-500"
      >
        <!-- Rent Start date -->
        <div class="flex items-center mb-4 capitalize">
          <label class="w-4/12 mr-4">Data Inizio</label>
          <input v-model="addRentRentStart" type="date" class="form-control" />
        </div>
        <!-- Rent End date -->
        <div class="flex items-center mb-4 capitalize">
          <label class="w-4/12 mr-4">Data Fine</label>
          <input v-model="addRentRentEnd" type="date" class="form-control" />
        </div>
        <!-- Initial Km Contract -->
        <div class="flex items-center mb-4 capitalize">
          <label class="w-4/12 mr-4">Km iniziali Contratto</label>
          <input
            v-model="addRentInitialKmContract"
            type="text"
            class="form-control"
          />
        </div>
        <!-- Total Km Contract -->
        <div class="flex items-center mb-4 capitalize">
          <label class="w-4/12 mr-4">Km da contratto</label>
          <input
            v-model="addRentContractedKm"
            type="text"
            class="form-control"
          />
        </div>
        <!-- Contracted Km Term -->
        <div class="flex items-center mb-4">
          <label class="w-4/12 mr-4">Vincolo Km</label>
          <select v-model="addRentContractedKmTerm" class="form-select">
            <option selected value="0">Nessuno</option>
            <option value="1">Annuale</option>
            <!-- <option value="2">Mensile</option> -->
          </select>
        </div>
        <!-- Initial Km Period -->
        <!-- <div
          v-if="addRentContractedKmTerm != 0"
          class="flex items-center mb-4 capitalize"
        >
          <label class="w-4/12 mr-4">Km iniziali Mese/Anno corrente</label>
          <input
            v-model="addRentInitialKmPeriod"
            type="text"
            class="form-control"
          />
        </div> -->
        <!-- Renter Name -->
        <div class="flex items-center mb-4 capitalize">
          <label class="w-4/12 mr-4">Nome Noleggiatore</label>
          <input v-model="addRentRenterName" type="text" class="form-control" />
        </div>

        <!-- Save or Cancel Vehicle Rent form -->
        <div class="flex justify-end">
          <button
            class="btn btn-secondary mr-2"
            @click.stop="cancelVehicleRentForm()"
          >
            Annulla
          </button>

          <button class="btn btn-primary" @click.stop="saveVehicleRentForm()">
            <loading-icon
              v-if="loading"
              icon="rings"
              color="white"
              class="mr-2"
            />
            Salva
          </button>
        </div>
      </div>

      <!-- Show Vehicle Rent data -->
      <div v-if="data && data.rent">
        <!-- Vehicle Rent info -->
        <div class="grid grid-cols-2 gap-4">
          <VehicleParameter
            name="Inizio noleggio"
            :value="dayjs(data.rent.rent_start).format('DD/MM/YYYY')"
          />
          <VehicleParameter
            v-if="data.rent.rent_end"
            name="Fine noleggio"
            :value="dayjs(data.rent.rent_end).format('DD/MM/YYYY')"
          />
          <VehicleParameter
            name="Km iniziali Contratto"
            :value="helper.format_km(data.rent.initial_km_contract)"
          />
          <VehicleParameter
            name="Km Massimi Contratto"
            :value="helper.format_km(data.rent.contracted_km)"
          />
          <VehicleParameter
            name="Vincolo Km"
            :value="
              data.rent.contracted_km_term == 0
                ? 'Nessun vincolo'
                : data.rent.contracted_km_term == 1
                ? 'Annuale'
                : data.rent.contracted_km_term == 2
                ? 'Mensile'
                : 'SCONOSCIUTO' //DEFAULT
            "
          />
          <VehicleParameter
            name="Noleggiatore"
            :value="data.rent.renter_name"
          />
        </div>

        <!-- Rent Elapsed Km and Days -->
        <div class="grid grid-cols-2 gap-4 mt-4">
          <!-- Km Contract -->
          <div class="flex flex-col text-center">
            <p class="font-bold text-lg">Km da inizio contratto</p>
            <p>{{ kmContractCurrent() }} / {{ kmContractTotal() }} Km</p>
            <p class="font-bold text-lg">{{ kmContractPercentage() }} %</p>
            <p class="text-sm text-green-500">
              Mancano {{ kmContractRemaining() }} Km
            </p>
          </div>

          <!-- Days Contract -->
          <div class="flex flex-col text-center">
            <p class="font-bold text-lg">Data fine contratto</p>
            <p>
              {{ timeContractCurrent() }} /
              {{ timeContractTotal() }}
            </p>
            <p>
              {{ daysContractCurrent() }} / {{ daysContractTotal() }} giorni
            </p>
            <p class="font-bold text-lg">{{ daysContractPercentage() }} %</p>
            <p class="text-sm text-green-500">
              Mancano {{ daysContractRemaining() }}
              {{ daysContractRemaining() == 1 ? "giorno" : "giorni" }}
            </p>
          </div>

          <!-- Km Period only if contracted_km_term is not 0 (No time period defined) -->
          <div
            v-if="
              data.rent.contracted_km_term != 0 && data.rent.initial_km_period
            "
            class="flex flex-col text-center"
          >
            <p class="font-bold text-lg">
              Km
              {{ data.rent.contracted_km_term == "1" ? "Annuali" : "Mensili" }}
              percorsi
            </p>
            <p>{{ kmPeriodCurrent() }} / {{ kmPeriodTotal() }} Km</p>
            <p class="font-bold text-lg">{{ kmPeriodPercentage() }} %</p>
            <p class="text-sm text-green-500">
              Mancano {{ kmPeriodRemaining() }} Km
            </p>
          </div>
          <!-- If yearly contract but no initial_km_period is given, we can't calc the period values -->
          <div
            v-if="
              data.rent.contracted_km_term == 1 && !data.rent.initial_km_period
            "
            class="flex flex-col text-center"
          >
            Impossibile calcolare valori annuali, il dato Km iniziali anno
            corrente non è stato trovato.
          </div>
        </div>

        <!-- Remove Vehicle Rent button -->
        <div v-if="data.rent" class="w-full mt-4 mr-2">
          <button
            class="btn btn-sm btn-danger mb-3 w-full"
            @click="removeVehicleRent(data.rent.id)"
          >
            <div class="w-8 h-8 rounded-md flex items-center justify-center">
              <TrashIcon />
            </div>
            <div class="ml-4 mr-auto">
              <div class="font-medium">Rimuovi dati Noleggio/Leasing</div>
            </div>
          </button>
        </div>
      </div>
    </div>

    <!-- Total Vehicle Km -->
    <div
      v-if="data && data.position && data.position.km !== null"
      class="box flex flex-row justify-center items-center gap-4 p-3"
    >
      <!-- adjusted vehicle km -->
      <VehicleParameter
        v-if="!editingKmOffset"
        :active="data.position.km !== null"
        :name="i18n.t('services.eyecar.km.name')"
        icon="MapIcon"
        :extraColumn="true"
        :value="helper.format_km(adjustedKm())"
      >
      </VehicleParameter>

      <!-- edit vehicle km -->
      <button
        v-if="!editingKmOffset"
        @click="editKmOffset()"
        title="Modifica Offset"
      >
        <EditIcon />
      </button>
      <div
        v-if="editingKmOffset"
        class="grid grid-cols-3 grid-rows-3 justify-center items-center gap-4 text-center"
      >
        <p>Km veicolo</p>
        <p>Offset Km</p>
        <p>Risultato</p>
        <p>{{ data.position.km }}</p>
        <input v-model="valueKmOffset" type="number" class="form-control" />
        <p>{{ data.position.km + parseInt(valueKmOffset) }}</p>
        <button
          @click="updateKmOffset()"
          class="col-span-3 btn btn-sm btn-primary"
        >
          <SaveIcon /> Salva Offset
        </button>
      </div>
    </div>

    <!-- Driver Activity -->
    <div class="box p-3 mt-4 text-center justify-items-center">
      <VehicleParameter
        :name="i18n.t('services.eyecar.driver.name')"
        :extraColumn="true"
        :icon="
          data && data.activity && data.activity.driver
            ? 'UserCheckIcon'
            : 'UserXIcon'
        "
        :setclass="
          () =>
            data && data.activity && data.activity.driver
              ? 'bg-reyesol-green'
              : 'bg-reyesol-red'
        "
        :value="
          data && data.activity && data.activity.driver
            ? data.activity.driver.name + ' ' + data.activity.driver.surname
            : i18n.t('services.eyecar.driver.none')
        "
      >
        <template
          v-if="data && data.activity && data.activity.driver"
          v-slot:extra
        >
          <div class="dropdown">
            <button
              class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
              aria-expanded="false"
            >
              <StopCircleIcon class="w-5 h-5" />
              {{ i18n.t("services.eyecar.activity.end") }}
            </button>
            <div class="dropdown-menu w-80">
              <div class="dropdown-menu__content box p-4">
                <CmdDatetime
                  :initial-value="new Date()"
                  :name="i18n.t('services.eyecar.activity.end')"
                  :description="
                    i18n.t('services.eyecar.activity.cmd.end_description')
                  "
                  :autosend="true"
                  :min-date="minDate"
                  :max-date="new Date()"
                  @send="(v) => sendEndCommand(v)"
                />
              </div>
            </div>
          </div> </template
        ><template v-else v-slot:extra>
          <div class="dropdown">
            <button
              class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
              aria-expanded="false"
            >
              <PlayCircleIcon class="w-5 h-5" />
              {{ i18n.t("services.eyecar.activity.start") }}
            </button>
            <div class="dropdown-menu w-80">
              <div class="dropdown-menu__content box p-4">
                <CmdDatetimeSelect
                  :initial-value="new Date().toString()"
                  :name="i18n.t('services.eyecar.activity.start')"
                  :placeholderSelect="
                    i18n.t('services.eyecar.activity.driver.placeholder')
                  "
                  :description="
                    i18n.t('services.eyecar.activity.cmd.start_description')
                  "
                  :options="
                    drivers_list.map((d) => ({
                      key: d.id,
                      label: d.name + ' ' + d.surname,
                    }))
                  "
                  :autosend="true"
                  :min-date="minDate"
                  :max-date="new Date()"
                  @send="(d, f) => sendStartCommand(d, f)"
                />
              </div>
            </div>
          </div>
        </template>
      </VehicleParameter>
    </div>

    <div class="py-6">
      <div class="text-lg capitalize mb-4">
        {{ i18n.t("services.eyecar.drivers_history") }}
        <Eyepicker v-model="daterange" />
      </div>
      <div v-if="!loadingHistory" class="max-h-80 overflow-y-auto">
        <div v-if="history && history.list">
          <div v-for="h in history.list.activity" :key="h.id">
            <div v-if="h" class="intro-y">
              <div
                class="box px-4 py-4 mb-3 flex items-center cursor-pointer mx-2 shadow-md hover:shadow-lg hover:bg-gray-200"
                :class="{
                  'bg-gray-200': selectedEvent && selectedEvent.id === h.id,
                }"
              >
                <div class="ml-4 mr-auto">
                  <div class="font-medium">
                    <DateAgo :datetime="h.period_from"></DateAgo>
                  </div>
                  <div class="text-gray-600 text-xs mt-0.5">Inizio guida</div>
                </div>
                <div class="ml-4 mr-auto">
                  <div v-if="h.period_to" class="font-medium">
                    <DateAgo :datetime="h.period_to"></DateAgo>
                  </div>
                  <div v-else class="font-medium text-reyesol-red">
                    In corso
                  </div>
                  <div class="text-gray-600 text-xs mt-0.5">Fine guida</div>
                </div>

                <VehicleParameter
                  :active="h.driver !== null"
                  :name="i18n.t('services.eyecar.driver.name')"
                  :icon="UserCheckIcon"
                  :value="
                    h.driver
                      ? h.driver.name + ' ' + h.driver.surname
                      : i18n.t('services.eyecar.driver.none')
                  "
                >
                </VehicleParameter>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="font-light text-xl text-gray-500">
          {{ i18n.t("general.no-results") }}
        </div>
      </div>
      <loading-icon v-else icon="rings" class="w-20 h-20" />
      <div class="btn-reyesol-report" @click="requestReport">
        <FileTextIcon /> {{ i18n.t("general.request-report") }}
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed, reactive, onUnmounted, onMounted } from "vue";
import { useI18n } from "vue3-i18n";
import { useStore } from "vuex";
import dayjs from "dayjs";
import EventBus from "@/libs/event-bus";
import { useDaterange } from "@/composables/daterange";
import toast from "@/services/toast";
import { helper } from "@/utils/helper";
import VehicleParameter from "@/components/vehicle/VehicleParameter.vue";
import CmdDatetime from "@/components/commands/CmdDatetime.vue";
import CmdDatetimeSelect from "@/components/commands/CmdDatetimeSelect.vue";
import { EditIcon } from "@zhuowenli/vue-feather-icons";
//import CmdRange from "@/components/commands/CmdRange.vue";
/*
        <div
          v-show="data.position.km_dirty"
          class="inline p-1 text-xs text-reyesol-red h-6 w-6"
        >
          <loading-icon icon="oval" />
        </div>

        <template v-slot:extra>
          <div
            v-if="
              vehicle.configurations &&
              vehicle.configurations.eyecar_cmd_enabled &&
              vehicle.configurations.eyecar_cmd_enabled == 'true'
            "
            class="dropdown"
          >
            <button
              class="dropdown-toggle btn btn-sm btn-warning p-1 mt-2"
              aria-expanded="false"
            >
              <span class="pr-1">{{
                i18n.t("services.eyecar.cmd.button")
              }}</span>
              <SlidersIcon class="w-5 h-5" />
            </button>
            <div class="dropdown-menu w-80">
              <div class="dropdown-menu__content box p-4">
                <CmdRange
                  :initial-value="data.position.km"
                  :name="i18n.t('services.eyecar.cmd.km')"
                  :description="i18n.t('services.eyecar.cmd.km_description')"
                  @send="(v) => sendKmCommand(v)"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <button
              class="btn btn-sm border-gray-500 p-1 mt-2"
              aria-expanded="false"
            >
              <span class="pr-1">{{ i18n.t("services.eyecontrol.name") }}</span>
              <LockIcon class="w-5 h-5" />
            </button>
          </div>
        </template>
*/
export default {
  components: {
    VehicleParameter,
    CmdDatetime,
    CmdDatetimeSelect,
    EditIcon,
    //CmdRange
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const editingKmOffset = ref(false);
    const valueKmOffset = ref(null);
    const valueOwnershipType = ref(null);

    const addingVehicleRent = ref(false);
    const addRentRentStart = ref(dayjs().format("YYYY-MM-DD"));
    const addRentRentEnd = ref(dayjs().format("YYYY-MM-DD"));
    const addRentInitialKmContract = ref(null);
    const addRentInitialKmPeriod = ref(null);
    const addRentContractedKm = ref(null);
    const addRentContractedKmTerm = ref("0");
    const addRentRenterName = ref(null);

    const vehicle = computed(() => store.getters["globalMap/selectedVehicle"]);
    const data = reactive(ref(null));
    const driver_activities_data = reactive(ref(null));
    const history = reactive(ref(null));
    const loadingHistory = ref(false);
    const loading = ref(false);
    const addActive = ref(false);
    const addExpenseActive = ref(false);
    const addType = ref(0);
    const subtypes = ref([0, 1, 2, 3, 4, 5, 6, 7]);
    const fridge_time_config = ref(12);
    const fridge_hours_config = ref(1500);
    const recorder_time_config = ref(12);
    const fgas_time_config = ref(12);
    const maintenance_km_config = ref(1500);
    const maintenance_time_config = ref(12);
    const addDate = ref(dayjs().format("YYYY-MM-DD"));
    const addData = ref(null);
    const selectedEvent = ref(null);
    const addDescription = ref("");
    const drivers_list = ref([]);
    const minDate = ref(null);
    const {
      daterange,
      from: historyFrom,
      to: historyTo,
    } = useDaterange({
      onChange: getHistory,
    });

    // Tabs
    const activeTab = ref("service");
    // watch service visibility
    watch(
      () => props.active,
      () => {
        if (props.active) initService();
        else destroyService();
      },
    );

    // init service
    async function initService() {
      data.value = null;
      const dataResult = await store.dispatch(
        "driver_activities/getVehicleData",
        vehicle.value.id,
      );

      data.value = dataResult.validated;
      if (data.value && data.value.activity)
        minDate.value = data.value.activity.period_from;

      valueKmOffset.value = data.value.km_offset;
      valueOwnershipType.value = data.value.ownership_type;

      const response = await store.dispatch("drivers/getCollection");
      drivers_list.value = response.validated;
      getHistory();
    }

    async function editKmOffset() {
      editingKmOffset.value = true;
    }

    async function updateKmOffset() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("vehicles/setKmOffset", {
        vehicleId: vehicle.value.id,
        kmOffsetValue: valueKmOffset.value,
      });

      if (!result.success) {
        if (result.data["name"] != undefined) {
          name_error.value = true;
          name_error_array.value = result.data["name"];
        }

        for (const key in result.data) {
          toast.error(result.data[key]);
        }
        toast.error(result.errorMessage);
      }

      if (result.success) {
        editingKmOffset.value = false;
        initService();
      }

      loading.value = false;
    }

    async function updateOwnershipType() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("vehicles/setOwnershipType", {
        vehicleId: vehicle.value.id,
        ownershipTypeValue: valueOwnershipType.value,
      });

      if (!result.success) {
        if (result.data["name"] != undefined) {
          name_error.value = true;
          name_error_array.value = result.data["name"];
        }

        for (const key in result.data) {
          toast.error(result.data[key]);
        }
        toast.error(result.errorMessage);
      }

      if (result.success) {
        initService();
      }

      loading.value = false;
    }

    async function addVehicleRent() {
      addingVehicleRent.value = true;
    }

    async function cancelVehicleRentForm() {
      addingVehicleRent.value = false;
    }

    async function saveVehicleRentForm() {
      loading.value = true;
      let result = null;

      //if yearly contract has started less than a year ago, initial_km_period = initial_km_contract
      let initialKmPeriod = null;
      if (addRentContractedKmTerm.value == 1) {
        const rentStartDate = dayjs(addRentRentStart.value);
        const todayDate = dayjs();
        const daysDifference = todayDate.diff(rentStartDate, "day");
        if (daysDifference < 365)
          initialKmPeriod = addRentInitialKmContract.value;
      }

      result = await store.dispatch("vehicle_rents/store", {
        vehicle_id: vehicle.value.id,
        rent_start: addRentRentStart.value,
        rent_end: addRentRentEnd.value,
        initial_km_contract: addRentInitialKmContract.value,
        initial_km_period: initialKmPeriod,
        contracted_km: addRentContractedKm.value,
        contracted_km_term: addRentContractedKmTerm.value,
        renter_name: addRentRenterName.value,
      });

      if (!result.success) {
        if (result.data["name"] != undefined) {
          name_error.value = true;
          name_error_array.value = result.data["name"];
        }

        for (const key in result.data) {
          toast.error(result.data[key]);
        }
        toast.error(result.errorMessage);
      }

      if (result.success) {
        addingVehicleRent.value = false;
        initService();
      }

      loading.value = false;
    }

    function removeVehicleRent(id) {
      EventBus.emit("confirm-dialog:show", {
        title: "Rimozione Dati Noleggio/Leasing",
        text: "Sei sicuro di voler rimuovere i dati Noleggio/Leasing e impostare l'auto come di proprietà?",
        callback: async (confirm) => {
          if (confirm) {
            const vehicleRentId = id;
            const result = await store.dispatch(
              "vehicle_rents/delete",
              vehicleRentId,
            );
            valueOwnershipType.value = 0;
            updateOwnershipType();
            if (result.success) {
              toast.success("Dati Noleggio/Leasing rimossi con successo.");
              initService();
            }
          }
        },
      });
    }

    function adjustedKm() {
      if (this.data.km_offset)
        return this.data.position.km + parseInt(this.data.km_offset);
      return this.data.position.km;
    }

    function kmContractTotal() {
      return this.data.rent.contracted_km;
    }

    function kmContractCurrent() {
      return this.adjustedKm() - this.data.rent.initial_km_contract;
    }

    function kmContractRemaining() {
      return this.kmContractTotal() - this.kmContractCurrent();
    }

    function kmContractPercentage() {
      return Math.round(
        (this.kmContractCurrent() / this.kmContractTotal()) * 100,
      );
    }

    function daysContractTotal() {
      const rentEndDate = dayjs(this.data.rent.rent_end);
      const rentStartDate = this.data.rent.rent_start;
      const daysDifference = rentEndDate.diff(rentStartDate, "day");
      return daysDifference;
    }

    function daysContractCurrent() {
      const rentStartDate = dayjs(this.data.rent.rent_start);
      const todayDate = dayjs();
      const daysDifference = todayDate.diff(rentStartDate, "day");
      return daysDifference;
    }

    function daysContractRemaining() {
      return this.daysContractTotal() - this.daysContractCurrent();
    }

    function daysContractPercentage() {
      if (this.daysContractTotal() == 0) return 100;
      return Math.round(
        (this.daysContractCurrent() / this.daysContractTotal()) * 100,
      );
    }

    function timeDifferenceToYearsAndMonths(start, end) {
      const dateStart = dayjs(start);
      const dateEnd = dayjs(end);

      const years = dateEnd.diff(dateStart, "year");
      const months = dateEnd.diff(dateStart, "month") % 12;

      const result = [];

      if (years > 0) {
        result.push(years === 1 ? "un anno" : `${years} anni`);
      }

      if (months > 0) {
        result.push(months === 1 ? "un mese" : `${months} mesi`);
      }

      if (result.length === 0) {
        return "meno di un mese";
      }

      return result.join(" e ");
    }

    function timeContractTotal() {
      const rentEndDate = this.data.rent.rent_end;
      const rentStartDate = this.data.rent.rent_start;

      return timeDifferenceToYearsAndMonths(rentStartDate, rentEndDate);
    }

    function timeContractCurrent() {
      const todayDate = dayjs();
      const rentStartDate = this.data.rent.rent_start;

      return timeDifferenceToYearsAndMonths(rentStartDate, todayDate);
    }

    function timeContractRemaining() {
      const todayDate = dayjs();
      const rentStartDate = this.data.rent.rent_start;

      return timeDifferenceToYearsAndMonths(rentStartDate, todayDate);
    }

    function kmPeriodTotal() {
      const rentEndDate = dayjs(this.data.rent.rent_end);
      const rentStartDate = this.data.rent.rent_start;
      const todayDate = dayjs().startOf("day");
      const monthsDifferenceFromRentStart = rentEndDate.diff(
        rentStartDate,
        "month",
        true,
      );
      const monthsDifferenceFromToday = rentEndDate.diff(
        todayDate,
        "month",
        true,
      );
      const excessMonths = monthsDifferenceFromRentStart % 12;
      let singleMonthTotal = this.kmContractTotal();

      if (monthsDifferenceFromRentStart >= 1)
        singleMonthTotal =
          this.kmContractTotal() / monthsDifferenceFromRentStart;
      let periodTotal = singleMonthTotal; //default if month term selected
      if (this.data.rent.contracted_km_term == 1) {
        periodTotal = singleMonthTotal * 12; //default to one year
        //if selected year term, handle cases of less than or equal to a year
        if (monthsDifferenceFromRentStart <= 12) {
          periodTotal = this.kmContractTotal();
        } else {
          if (excessMonths > 0 && monthsDifferenceFromToday <= excessMonths)
            periodTotal = singleMonthTotal * excessMonths;
        }
      }

      /* console.log("");
      console.log("DATI CALCOLO KM TOTALE PERIODO");
      console.log("Inizio noleggio: " + rentStartDate);
      console.log("Fine noleggio: " + rentEndDate.format("YYYY-MM-DD"));
      console.log("Oggi: " + todayDate.format("YYYY-MM-DD"));
      console.log(
        "Differenza mesi da Inizio noleggio: " + monthsDifferenceFromRentStart,
      );
      console.log("Differenza mesi da oggi: " + monthsDifferenceFromToday);
      console.log("Km singolo mese: " + singleMonthTotal);
      console.log("Km periodo (anno o mese): " + periodTotal);
      console.log("FINE DATI CALCOLO KM TOTALE PERIODO");
      console.log(""); */

      return Math.round(periodTotal);
    }

    function kmPeriodCurrent() {
      return this.adjustedKm() - this.data.rent.initial_km_period;
    }

    function kmPeriodRemaining() {
      return this.kmPeriodTotal() - this.kmPeriodCurrent();
    }

    function kmPeriodPercentage() {
      return Math.round((this.kmPeriodCurrent() / this.kmPeriodTotal()) * 100);
    }

    function updatePosition(position) {
      if (data.value == null) return;
      data.value.position = position;
    }

    onMounted(() => {
      EventBus.on("vehicle:updates:position", updatePosition);
    });
    onUnmounted(() => {
      EventBus.off("vehicle:updates:position");
    });

    // requestReport service
    async function requestReport() {
      emit("report-request", ["drivers.report"], "drivers", ["drivers.report"]);
    }

    async function addNewExpense() {
      addExpenseActive.value = true;
    }

    async function cancelForm() {
      addActive.value = false;
      addExpenseActive.value = false;
    }

    async function addNewMaintenance() {
      addActive.value = true;
    }

    // destroy service
    function destroyService() {}
    // get history
    async function getHistory() {
      if (!props.active) return;
      loadingHistory.value = true;

      const historyResult = await store.dispatch(
        "driver_activities/getVehicleHistory",
        {
          vehicleId: vehicle.value.id,
          dateFrom: dayjs(historyFrom.value).format(),
          dateTo: dayjs().isSame(dayjs(historyTo.value), "day")
            ? dayjs().format()
            : dayjs(historyTo.value).format(),
        },
      );
      history.value = historyResult.validated;
      loadingHistory.value = false;
    }

    // save
    async function save() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("driver/addDriver", {
        vehicleId: vehicle.value.id,
        driverId: driver.value,
        preiodFrom: addDate.value,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        addActive.value = false;

        const dataResult = await store.dispatch(
          "driver/getVehicleData",
          vehicle.value.id,
        );
        data.value = dataResult.validated;
      }

      loading.value = false;
    }

    async function saveExpense() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("eyeservice/addMaintenance", {
        vehicleId: vehicle.value.id,
        type: 4,
        date: addDate.value,
        description: addDescription.value,
        data: addData.value,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        addActive.value = false;

        const dataResult = await store.dispatch(
          "eyeservice/getVehicleData",
          vehicle.value.id,
        );
        data.value = dataResult.validated;
      }

      loading.value = false;
    }

    async function sendKmCommand(v) {
      loading.value = true;
      console.log(v);
      loading.value = false;
    }

    async function sendEndCommand(v) {
      loading.value = true;
      let result = null;

      result = await store.dispatch("driver_activities/setActivity", {
        vehicleId: vehicle.value.id,
        to: dayjs(v).format(),
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        initService();
      }

      loading.value = false;
    }

    async function sendStartCommand(driver, from) {
      loading.value = true;
      let result = null;
      console.log("driver", driver.value);
      result = await store.dispatch("driver_activities/setActivity", {
        vehicleId: vehicle.value.id,
        driverId: driver.value,
        from: dayjs(from).format(),
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        initService();
      }

      loading.value = false;
    }

    async function save_config() {
      loading.value = true;
      let result = null;

      result = await store.dispatch("eyeservice/configMaintenance", {
        vehicleId: vehicle.value.id,
        fridge_time_config: fridge_time_config.value,
        fridge_hours_config: fridge_hours_config.value,
        maintenance_time_config: maintenance_time_config.value,
        maintenance_km_config: maintenance_km_config.value,
        fgas_time_config: fgas_time_config.value,
        recorder_time_config: recorder_time_config.value,
      });
      if (!result.success) {
        toast.error(result.errorMessage);
      }

      if (result.success) {
        const dataResult = await store.dispatch(
          "eyeservice/getVehicleData",
          vehicle.value.id,
        );
        data.value = dataResult.validated;
      }

      loading.value = false;
    }

    return {
      editKmOffset,
      editingKmOffset,
      valueKmOffset,
      valueOwnershipType,
      updateKmOffset,
      updateOwnershipType,
      addVehicleRent,
      addingVehicleRent,
      addRentRentStart,
      addRentRentEnd,
      addRentInitialKmContract,
      addRentInitialKmPeriod,
      addRentContractedKm,
      addRentContractedKmTerm,
      addRentRenterName,
      cancelVehicleRentForm,
      saveVehicleRentForm,
      removeVehicleRent,
      adjustedKm,
      kmContractTotal,
      kmContractCurrent,
      kmContractRemaining,
      kmContractPercentage,
      daysContractTotal,
      daysContractCurrent,
      daysContractRemaining,
      daysContractPercentage,
      timeContractTotal,
      timeContractCurrent,
      timeContractRemaining,
      kmPeriodTotal,
      kmPeriodCurrent,
      kmPeriodRemaining,
      kmPeriodPercentage,
      i18n: useI18n(),
      dayjs,
      vehicle,
      activeTab,
      data,
      minDate,
      driver_activities_data,
      fridge_time_config,
      fridge_hours_config,
      maintenance_time_config,
      maintenance_km_config,
      recorder_time_config,
      fgas_time_config,
      history,
      addNewMaintenance,
      loadingHistory,
      loading,
      subtypes,
      addActive,
      addExpenseActive,
      addNewExpense,
      cancelForm,
      selectedEvent,
      addType,
      addDate,
      addData,
      addDescription,
      daterange,
      save,
      helper,
      requestReport,
      saveExpense,
      save_config,
      sendEndCommand,
      sendKmCommand,
      drivers_list,
      sendStartCommand,
    };
  },
};
</script>

<style></style>
